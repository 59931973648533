import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaFacebookF, FaSnapchatGhost, FaTiktok } from "react-icons/fa";
import { FaInstagram, FaSquareXTwitter } from "react-icons/fa6";
import { t } from "i18next";

const socialLinks = {
  insta: "https://www.instagram.com/oktoonshow/",
  fb: "https://www.facebook.com/profile.php?id=61560119503920&mibextid=LQQJ4d",
  tiktok: "https://www.tiktok.com/@oktoonshow",
  x: "https://x.com/oktoonshow",
  snap: "https://snapchat.com/t/J3zWVkGa",
};
const Footer = () => {
  // console.log(language);
  // const location = React.useLocation();
  // const [url, setUrl] = React.useState(null);
  // React.useEffect(() => {
  //   setUrl(location.pathname);
  // }, [location]);

  return (
    <section className="footer" id="footer">
      <Container>
        <Row>
          <Col lg={6}>
            <h3>
              <span>
                <br /> <span>{t("footer.saudiArab")}</span>
                {t("footer.footerHead")}
              </span>
            </h3>
          </Col>
          <Col lg={6}>
            <div className="footSocialSmall">
              <div className="sociallinks gap-3">
                <Link to={socialLinks.insta} target="_blank">
                  <FaInstagram size={18} />
                </Link>
                <Link to={socialLinks.fb} target="_blank">
                  <FaFacebookF size={15} />
                </Link>
                <Link to={socialLinks.x} target="_blank">
                  <FaSquareXTwitter size={15} />
                </Link>
                <Link to={socialLinks.snap} target="_blank">
                  <FaSnapchatGhost size={15} />
                </Link>
                <Link to={socialLinks.tiktok} target="_blank">
                  <FaTiktok size={15} />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="footCopyBox">
              <div className="footCopyright_link">
                <Link to="/privacy-policy">{t("footer.privacy")}</Link>
                <Link to="/terms">{t("footer.terms")}</Link>
                {/* <Link
                  className={url === "/privacypolicy" ? " active" : ""}
                  to="/privacypolicy"
                >
                  Privacy policy
                </Link>
                <Link
                  className={url === "/termofservice" ? " active" : ""}
                  to="/termofservice"
                >
                  Term of service
                </Link> */}
                <Link to="/about">{t("footer.aboutUs")}</Link>
                <Link to="/content-delivery-policy">{t("footer.cdp")}</Link>
                <Link to="/refund-policy">{t("footer.refund")}</Link>
              </div>
              <div className="footyear">
                <h5>© {new Date().getFullYear()}</h5>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;

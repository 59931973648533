import React, { useEffect, useState } from "react";
import LegalPagesHead from "../../component/LegalPagesHead";
import SuccessGif from "../../component/images/success.gif";
import { t } from "i18next";
import { getTransactionStatusService } from "../../services/user";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const TransactionSuccess = () => {
  const [redirectUrl, setRedirectUrl] = useState("");
  const navigate = useNavigate();
  const [time, setTime] = useState("");
  const [resendDisabled, setResendDisabled] = useState(true);

  useEffect(() => {
    checkTransactionStatus();
  }, []);
  const checkTransactionStatus = async () => {
    try {
      const searchQuery = new URLSearchParams(window.location.search);
      const ref = searchQuery.get("ref");
      if (ref) {
        const res = await getTransactionStatusService(ref);
        if (res.data.status) {
          // toast.success(t("pages.modals.rentSuccessMsg"));
          const { details } = res.data;
          setRedirectUrl(details);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  function startTimer(duration = 10) {
    var timer = duration,
      minutes: string | number,
      seconds;
    let int = setInterval(function () {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setTime(String(seconds));
      if (+minutes === 0 && +seconds === 0) {
        // navigate(redirectUrl, {
        //   replace: true,
        // });
      }
      if (--timer < 0) {
        timer = duration;
        clearInterval(int);
      }
    }, 1000);
  }

  // useEffect(() => {
  //   startTimer();
  // }, []);

  return (
    <div className="text-center">
      <LegalPagesHead title={t("thankYou")} />
      <div className="homeBannerContent_box px-5 mt-5">
        <div>
          <iframe src="https://lottie.host/embed/9b3bc142-5419-4d06-b6a9-d92938a6de67/HKjNKGLAaQ.json"></iframe>
        </div>
        <h1 className="mb-5 f-5">
          {t("paymentSuccess")
            .split(" ")
            .map((i, index) => (
              <>
                {index % 2 === 0 ? (
                  <span className="text-white" key={i}>
                    {" "}
                    {i}
                  </span>
                ) : (
                  <span className="primary-text" key={i}>
                    {" "}
                    {i}
                  </span>
                )}
              </>
            ))}
        </h1>
        <div className="my-5">
          {/* <span className="text-white fs-4">
            {t("dontRefresh", {
              time: time,
            })}
          </span> */}
          <Button
            onClick={() =>
              navigate(redirectUrl, {
                replace: true,
              })
            }
            className="cBtn fs-4 px-5"
            variant="primary"
          >
            {t("proceed")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TransactionSuccess;

import React, { FC, Suspense, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { WithChildren } from "../../../utils/types";
import { Colors } from "../../../utils/colors";
import { TOKEN_KEY } from "../../../utils/constants";
import { getTransactionStatusService } from "../../../services/user";
import toast from "react-hot-toast";
import { t } from "i18next";

const PrivateRoute = ({ children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const checkLoggedIn = useCallback(() => {
    let token = localStorage.getItem(TOKEN_KEY);
    if (!token)
      return navigate("/login", {
        replace: true,
      });
  }, [navigate]);
  useEffect(() => {
    checkLoggedIn();
  }, [checkLoggedIn]);

  useEffect(() => {
    if (location.pathname === "/transaction-success") checkTransactionStatus();
  }, [location.pathname]);
  const checkTransactionStatus = async () => {
    try {
      setLoader(true);
      const searchQuery = new URLSearchParams(location.search);
      const ref = searchQuery.get("ref");
      if (ref) {
        const res = await getTransactionStatusService(ref);
        if (res.data.status) {
          // toast.success(t("pages.modals.rentSuccessMsg"));
          const { details } = res.data;
          // let timeout = setTimeout(() => {
          // navigate(`${details}`, {
          //   replace: true,
          // });
          // }, 10000);
          // return () => {
          //   clearTimeout(timeout);
          // };
        }
      } else {
        navigate("/", {
          replace: true,
        });
      }
    } catch (error) {
      console.log(error);
      navigate("/", {
        replace: true,
      });
    } finally {
      setLoader(false);
    }
  };

  return <SuspensedView>{loader ? null : children}</SuspensedView>;
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  TopBarProgress.config({
    barColors: {
      "0": Colors.primarycolor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoute, SuspensedView };

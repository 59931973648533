import axios from "axios";
import { refreshTokenService } from "./user";
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from "../utils/constants";

const baseUrl =
  process.env.NODE_ENV === "development"
    ? "https://staging.showok.net/api"
    : process.env.REACT_APP_API_URL;
export const ASSETS_URL = process.env.REACT_APP_CLOUNDFRON_URL;
export const vimeoPlayerUrl = "https://player.vimeo.com/video/";

const instance = axios.create({
  baseURL: baseUrl,
});

const fileInstance = axios.create({
  baseURL: baseUrl,
});
const publicInstance = axios.create({
  baseURL: baseUrl,
});

const onRefresh = async () => {
  try {
    const token = localStorage.getItem(REFRESH_TOKEN_KEY);
    const res = await refreshTokenService({
      refreshToken: token,
    });
    if (res.data.status) {
      localStorage.setItem(TOKEN_KEY, res.data.token);
      localStorage.setItem(REFRESH_TOKEN_KEY, res.data.refresh);
      return { token: res.data.token };
    }
  } catch (e) {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.location.reload();
    console.log(e);
  }
};
instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (config.headers != null) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    const originalRequest = error.response?.config;
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    try {
      if (!error.response.data.status && error.response.status === 401) {
        originalRequest._retry = true;
        const res = await onRefresh();
        if (res?.token) {
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res?.token;
          fileInstance.defaults.headers[
            "Authorization"
          ] = `Bearer ${res?.token}`;
          return instance(originalRequest);
        } else {
          localStorage.removeItem(TOKEN_KEY);
          localStorage.removeItem(REFRESH_TOKEN_KEY);
          return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }
);
fileInstance.interceptors.response.use(
  async function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (!error.response.data.status && error.status === 401) {
      localStorage.removeItem(TOKEN_KEY);
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

fileInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(TOKEN_KEY);

  if (config.headers != null) {
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

export { instance, fileInstance, publicInstance };

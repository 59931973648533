import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProfileService } from "../../services/user";
import { getPaymentMethodsService } from "../../services/payment-methods";

export const getUserThunk = createAsyncThunk("user/getUser", async () => {
  try {
    const res = await getProfileService();
    if (res.data.status) {
      // if (
      //   window.location.pathname !== "/otp-verification" &&
      //   !res.data.user.isVerified
      // )
      //   window.location.href = `/otp-verification?m=${res.data.user.email}&h=${res.data.hash}&v=true`;
      return res.data.user;
    }
  } catch (e: any) {
    console.log(e);
    if (e.response.status === 400) {
      localStorage.clear();
      window.location.href = "/";
    }
    return {};
  }
});
export const getUserPaymentMethodsThunk = createAsyncThunk(
  "user/getPaymentMethods",
  async () => {
    try {
      const res = await getPaymentMethodsService();
      if (res.data.status) {
        return res.data.list;
      }
    } catch (e) {
      console.log(e);
      return [];
    }
  }
);

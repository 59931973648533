import { createSlice } from "@reduxjs/toolkit";
import { AssetsState } from "../../utils/types";
import { getAssetsList } from "../thunks/assets";

// Define a type for the slice state

// Define the initial state using that type
const initialState: AssetsState = {
  list: [],
};

export const assetsSlice = createSlice({
  name: "assets",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAssetsList.fulfilled, (state, action) => {
      state.list = action.payload;
    });
  },
});
export default assetsSlice.reducer;

import { t } from "i18next";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import "react-slideshow-image/dist/styles.css";
import { useAppSelector } from "../../../app/hooks";
import { copyString } from "../../../utils";
import { FaCheck, FaClipboard } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";

const ReferEarnModal = (props: any) => {
  const { user } = useAppSelector((state) => state.auth);
  const [isCopied, setIsCopied] = useState(false);

  const onCopyLink = () => {
    copyString(
      `${window.location.origin}/login?referral_code=${user?.referralCode}`
    );
    setIsCopied(true);
    let timeout = setTimeout(() => {
      setIsCopied(false);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  };
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onExited={() => setIsCopied(false)}
    >
      <Modal.Body className="p-4">
        <div className="d-flex justify-content-between align-items-center">
          <div
            style={{
              height: "5%",
              width: "5%",
            }}
          ></div>
          <h4 className="mt-3">{t("referEarn")}</h4>
          <IoMdCloseCircle
            onClick={() => props.onHide()}
            style={{
              color: "white",
              height: "5%",
              width: "5%",
              cursor: "pointer",
            }}
          />
        </div>
        <div className="mt-5">
          {!user?.canRefer ? (
            <p>{t("cantReferTxt")}</p>
          ) : (
            <div className="row align-items-center">
              <div className="col-md-8 col-lg-8 col-sm-12">
                <div className="passwordPos">
                  <Form.Control
                    value={`https://showok.net/login?referral_code=${user?.referralCode}`}
                    type={"text"}
                    //   onChange={}
                    placeholder={""}
                  />

                  {isCopied ? (
                    <FaCheck
                      style={{
                        color: "green",
                      }}
                    />
                  ) : (
                    <FaClipboard />
                  )}
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12">
                <Button
                  disabled={isCopied}
                  className="cBtn w-100 py-3 copyLinkBtn"
                  variant="primary"
                  onClick={() => {
                    onCopyLink();
                  }}
                >
                  {t("copyLink")}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="modalBtn mt-4"></div>
      </Modal.Body>
    </Modal>
  );
};

export default ReferEarnModal;

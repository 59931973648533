import React, { FormEvent, useEffect, useState } from "react";
import { Col, Container, Image, Row, Button } from "react-bootstrap";
import TextInputWithLabel from "../../component/customcomponents/TextInputWithLabel";
import UserImg from "../../component/images/userimg.png";
import TextSelectOptions from "../../component/customcomponents/TextSelectOptions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { changePasswordService, updateUserService } from "../../services/user";
import toast from "react-hot-toast";
import { getUserThunk } from "../../redux/thunks/user";
import { t } from "i18next";
import { object, string } from "yup";
import { useNavigate } from "react-router-dom";
import TextInputPassword from "../../component/customcomponents/TextInputPassword";

const ChangePassword = () => {
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const [data, setData] = useState<any>({});

  const handleChange = (name: string, val: string) => {
    setData({
      ...data,
      [name]: val,
    });
  };
  const userSchema = object({
    oldpassword: string().required("Current Password is required"),
    password: string().when("oldpassword", {
      is: (valid: any) => valid,
      then: () =>
        string()
          .required("Password is required")
          .min(5, "The password must be atleast 5 characters long."),
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%_#*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      //   "The password must be atleast 6 characters long and include one lowercase letter, one uppercase letter, one digit, and one special characters."
      // ),
    }),
    // confirmPassword: string().when("password", {
    //   is: (valid: any) => valid,
    //   then: () =>
    //     string()
    //       .required("Confirm Password is required")
    //       .matches(
    //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%_#*?&])[A-Za-z\d@$!%*?&]{6,}$/,
    //         "The password must be atleast 6 characters long and include one lowercase letter, one uppercase letter, one digit, and one special characters."
    //       )
    //       .matches(data.password, "Password doesn't match"),
    // }),
  });
  const onUpdate = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await userSchema.validate(data);
      setLoader(true);
      const res = await changePasswordService(data);
      if (res.data.status) {
        dispatch(getUserThunk());
        toast.success(res.data.message);
        navigate("/");
        setLoader(false);
      } else {
        setLoader(false);
        setErrorMsg(res.data.message);
      }
    } catch (e: any) {
      if (e?.errors && e?.errors.length > 0) {
        setErrorMsg(e?.errors[0]);
      } else if (e?.response?.data) {
        setErrorMsg(
          e.response?.data?.message ||
            (e.response?.data?.errors
              ? e.response?.data?.errors[0]?.msg
              : "Sorry, something went wrong")
        );
      } else {
        setErrorMsg("Sorry, something went wrong");
        console.log(e);
      }
      setLoader(false);
    }
  };
  return (
    <React.Fragment>
      <section className="ptb">
        <Container className="payments_main">
          <Row>
            <Col className="text-center">
              <h2 className="title">
                <span>{t("changePass")}</span>
              </h2>
            </Col>
          </Row>
          <div className="align-items-center d-flex flex-column">
            <div className="userBox w-50">
              <TextInputPassword
                value={data.oldpassword}
                onChange={(e: any) =>
                  handleChange("oldpassword", e.target.value)
                }
                label={t("pages.changePass.currentPass")}
                placeholder={t("pages.login.passwordPlaceholder")}
                type="password"
              />
              <TextInputPassword
                value={data.password}
                onChange={(e: any) => handleChange("password", e.target.value)}
                label={t("pages.changePass.newPass")}
                placeholder={t("pages.login.passwordPlaceholder")}
                type="password"
              />
              {/* <TextInputPassword
                value={data.confirmPassword}
                onChange={(e: any) =>
                  handleChange("confirmPassword", e.target.value)
                }
                label={t("pages.changePass.confirmNewPass")}
                placeholder={t("pages.login.passwordPlaceholder")}
                type="password"
              /> */}
              {errorMsg && <p className="text-danger">{errorMsg}</p>}

              <div className="profileBtn text-center">
                <Button
                  disabled={loader}
                  onClick={onUpdate}
                  variant="primary"
                  className="fullWidth mt-4 w100"
                >
                  {t("submit")}
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ChangePassword;

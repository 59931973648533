import React, { useCallback, useEffect, useState } from "react";
import { Container, Dropdown, Modal, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Logo from "../images/logo.svg";
import UserImg from "../images/userimg.png";
import { IoIosArrowDown } from "react-icons/io";
import {
  LanguageType,
  useAppDispatch,
  useAppSelector,
  useLanguage,
} from "../../app/hooks";
import { setIsLoggedIn } from "../../redux/slices/user";
import { t } from "i18next";
// import { useMediaQuery } from "react-responsive";
import { updateUserService } from "../../services/user";
import { getUserThunk } from "../../redux/thunks/user";
import ReferEarnModal from "../customcomponents/ReferEarn";
import { REFRESH_TOKEN_KEY, TOKEN_KEY } from "../../utils/constants";

interface HeaderProps {
  stop: () => void;
}

const Header: React.FC<HeaderProps> = ({ stop }) => {
  const { isLoggedIn, user, isVideoPlaying } = useAppSelector(
    (state) => state.auth
  );
  // const isMobile = useMediaQuery({ maxWidth: 767 });
  const { language } = useLanguage();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const [sticky, setSticky] = useState("");
  const classes = `${sticky}`;
  const isSticky = useCallback(() => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 5 || isVideoPlaying ? "is-sticky" : "";
    setSticky(stickyClass);
  }, [isVideoPlaying]);
  const [modalShow, setModalShow] = React.useState(false);
  const [showReferModal, setShowReferModal] = React.useState(false);
  const [modalShow1, setModalShow1] = React.useState(false);
  const [expanded, setExpaned] = useState(false);
  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [isSticky]);
  useEffect(() => {
    isSticky();
  }, [isVideoPlaying, isSticky]);

  const onLogout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    setIsLoggedIn(false);
    dispatch(setIsLoggedIn(false));
    setModalShow(false);
    window.location.href = "/login";
  };

  useEffect(() => {
    setExpaned(false);
  }, [location]);

  return (
    <React.Fragment>
      <Navbar
        id="header"
        expanded={expanded}
        collapseOnSelect
        expand="lg"
        className={classes}
      >
        <Container className="d-flex justify-content-between">
          <Navbar.Brand as={Link} onClick={() => stop()} to={`/`}>
            <Image src={Logo} width="160" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => {
              setExpaned(!expanded);
            }}
          />
          <Navbar.Collapse
            id="navbarScroll"
            style={{
              alignItems: "flex-end",
            }}
          >
            <Nav>
              <div className="btnBox langFlex">
                <div className="langSelect text-uppercase">
                  {language}{" "}
                  <span onClick={() => setModalShow1(true)}>
                    <IoIosArrowDown />
                  </span>
                </div>
                {!isLoggedIn ? (
                  <>
                    {/* Before Login */}
                    <Button
                      onClick={() => {
                        navigate("/login");
                      }}
                      variant="primary"
                      className="me-2 newBtn"
                    >
                      {t("loginOrSignup")}
                    </Button>
                    {/* <Button
                      onClick={() => {
                        navigate("/signup");
                      }}
                      variant="secondary newBtn1"
                    >
                      {t("signup")}
                    </Button> */}
                    {/* End Before Login */}
                  </>
                ) : (
                  <>
                    {/* After Login */}
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <Image
                          src={UserImg}
                          className="mx-2"
                          width={50}
                          style={{
                            borderRadius: 100,
                          }}
                          alt=""
                        />
                        <span className="px-2">{user?.name}</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item as={Link} to={`/profile`}>
                          {t("profile")}
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to={`/payments`}>
                          {t("payments")}
                        </Dropdown.Item>
                        {/* <Dropdown.Item
                          onClick={() => {
                            setShowReferModal(true);
                          }}
                        >
                          {t("referEarn")}
                        </Dropdown.Item> */}
                        {/* <Dropdown.Item as={Link} to={`/change-password`}>
                          {t("changePass")}
                        </Dropdown.Item> */}
                        <Dropdown.Item onClick={() => setModalShow(true)}>
                          {t("logout")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ReferEarnModal
        show={showReferModal}
        onHide={() => {
          setShowReferModal(false);
        }}
      />
      <LogoutModal
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
        onLogout={onLogout}
      />
      <DeleteModal
        show={modalShow1}
        onHide={() => {
          setModalShow1(false);
        }}
      />
    </React.Fragment>
  );
};

function LogoutModal(props: any) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4 className="mt-3">{t("logout")}</h4>
        <h3>
          <span style={{ opacity: 0.7, color: "#ffffff" }}>
            {t("logoutConfirm")}
          </span>{" "}
        </h3>
        <div className="modalBtn mt-4">
          <Button
            onClick={props.onLogout}
            variant="primary"
            className="me-3 cBtn"
          >
            {t("logout")}
          </Button>
          <Button
            className="cBtn"
            variant="outline-primary"
            onClick={props.onHide}
          >
            {t("cancel")}
          </Button>
        </div>
        <Image src={Logo} width="160" className="mb-3 mt-3" />
      </Modal.Body>
    </Modal>
  );
}

function DeleteModal(props: any) {
  const { setLanguage, language } = useLanguage();
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const onLanguageChange = async (locale: LanguageType) => {
    try {
      if (isLoggedIn) {
        const res = await updateUserService({
          language: locale,
        });
        if (res.data.status) {
          dispatch(getUserThunk());
          await setLanguage(locale);
          props.onHide();
        }
      } else {
        await setLanguage(locale);
        props.onHide();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4 className="mt-3">{t("selectLang")}</h4>
        <h3>{t("selectLangDesc")}</h3>
        <div className="languageBtn mt-2">
          <div className="modalBtn">
            <Button
              onClick={async () => {
                await onLanguageChange("en");
              }}
              variant="primary"
              className={`fullWidth mt-4 w100 ${
                language === "en" && "activeLanguage"
              }`}
            >
              English
            </Button>

            <Button
              onClick={async () => {
                await onLanguageChange("ar");
              }}
              variant="primary"
              className={`fullWidth mt-4 w100 ${
                language === "ar" && "activeLanguage"
              }`}
            >
              Arabic
            </Button>
            <Button
              onClick={async () => {
                await onLanguageChange("tr");
              }}
              variant="primary"
              className={`fullWidth mt-4 w100 ${
                language === "tr" && "activeLanguage"
              }`}
            >
              Turkish
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Header;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAssetsListService } from "../../services/user";

export const getAssetsList = createAsyncThunk(
  "assets/getAssetListThunk",
  async (query?: string) => {
    try {
      let q = query || "";
      const res = await getAssetsListService(q);
      if (res.data.status) {
        return res.data.assets;
      }
    } catch (e) {
      console.log(e);
      return [];
    }
  }
);

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { useLanguage } from "../../app/hooks";

const TextInputPassword = (props: any) => {
  const { language } = useLanguage();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  return (
    <Form.Group className="mb-3">
      <Form.Label>
        {props.label}
        <span className="requiresLable">{props.reqstart}</span>
      </Form.Label>
      <div className="passwordPos">
        {language === "ar" && (
          <>
            {isRevealPwd ? (
              <IoMdEye
                color="#3e3c43"
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            ) : (
              <IoMdEyeOff
                color="#3e3c43"
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            )}
          </>
        )}
        <Form.Control
          value={props.value}
          type={isRevealPwd ? "text" : "password"}
          onChange={props.onChange}
          placeholder={props.placeholder}
        />
        {language !== "ar" && (
          <>
            {isRevealPwd ? (
              <IoMdEye
                color="#3e3c43"
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            ) : (
              <IoMdEyeOff
                color="#3e3c43"
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            )}
          </>
        )}
      </div>
    </Form.Group>
  );
};

export default TextInputPassword;

import { instance, publicInstance } from "./config";

export const getProfileService = () => instance.get("/profile");
export const signupService = (data: any) =>
  publicInstance.post("/sign-up", data);
export const loginService = (data: any) => publicInstance.post("/login", data);
export const getUserTransactionsService = (query: string) =>
  instance.get("/transactions" + query);
export const updateUserService = (data: any) =>
  instance.patch("/user/update", data);
export const refreshTokenService = (data: any) =>
  publicInstance.post("/refresh", data);
export const forgotPassService = (data: any) =>
  publicInstance.post("/forgot-password", data);
export const verifyOtpService = (data: any) =>
  publicInstance.post("/verify-otp", data);

export const verifyPromoCodeService = (data: any) =>
  instance.post("/verify-promocode", data);

export const resetPassService = (data: any) =>
  publicInstance.post("/reset-password", data);
export const resendOtpService = (data: any) =>
  publicInstance.post("/resend-otp", data);
export const deletePaymentMethodService = (id: string) =>
  instance.delete("/user/payment-method/delete/" + id);
export const changePasswordService = (data: any) =>
  instance.patch("/change-passsword", data);
export const getAssetsListService = (query: string) =>
  instance.get("/assets/list" + query);
export const getTransactionStatusService = (query: string) =>
  instance.get("/transaction-status?ref=" + query);

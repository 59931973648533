import { instance } from "./config";

export const createPaymentMethodService = (data: any) =>
  instance.post("/payment-method", data);

export const getPaymentMethodsService = () => instance.get("/payment-method");
export const getClientSecretService = (data: any) =>
  instance.post("/payment/secret", data);
export const attachPaymentMthToIntentService = (data: any) =>
  instance.patch("/payment/method/attach", data);

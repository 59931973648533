import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translations.json";
import translationAR from "./locales/ar/translations.json";
import translationTR from "./locales/tr/translations.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
  tr: {
    translation: translationTR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",

  // interpolation: {
  //   escapeValue: false,
  // },
});

export default i18n;

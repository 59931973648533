import { createSlice } from "@reduxjs/toolkit";
import { getUserPaymentMethodsThunk, getUserThunk } from "../thunks/user";
import { UserState } from "../../utils/types";

// Define a type for the slice state

// Define the initial state using that type
const initialState: UserState = {
  isLoggedIn: false,
  user: {},
  paymentMethods: [],
  isVideoPlaying: false,
};

export const userSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
      if (!action.payload) {
        state.user = {};
      }
      return state;
    },
    setVideoPlaying: (state, action) => {
      state.isVideoPlaying = action.payload;
      if (!action.payload) {
        state.isVideoPlaying = false;
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserThunk.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    });
    builder.addCase(getUserPaymentMethodsThunk.fulfilled, (state, action) => {
      state.paymentMethods = action.payload;
    });
  },
});
export const { setIsLoggedIn, setVideoPlaying } = userSlice.actions;
export default userSlice.reducer;

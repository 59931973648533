import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "moment/locale/ar";
import "moment/locale/tr";
import moment from "moment";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type LanguageType = "ar" | "en" | "tr";

export const useLanguage = (): {
  language: LanguageType;
  setLanguage: (locale: LanguageType) => Promise<void>;
} => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = useCallback(
    async (locale: LanguageType) => {
      // let root = document.get
      if (document.body) {
        document.documentElement.lang = locale;
        document
          .getElementById("root")
          ?.setAttribute("dir", locale === "ar" ? "rtl" : "ltr");
      }
      localStorage.setItem("lng", locale);
      moment.locale(locale);
      await i18n.changeLanguage(locale);
    },
    [i18n]
  );

  useEffect(() => {
    handleChangeLanguage((localStorage.getItem("lng") as LanguageType) || "en");
  }, [handleChangeLanguage]);

  return useMemo(
    () => ({
      language: i18n.language as LanguageType,
      setLanguage: handleChangeLanguage,
    }),
    [handleChangeLanguage, i18n.language]
  );
};
